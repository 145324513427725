import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["authorization"] =
	localStorage.getItem("authToken");
axios.defaults.headers.post["Content-Type"] = "application/json";

const axiosRequest = async function (method, url, data) {
	try {
		const response = await axios({
			method,
			url,
			data,
		});
		return response.data;
	} catch (err) {
		console.log("!!", err);
		return {
			success: false,
			result: `Error ${err}`,
		};
	}
};

export const PremintXYZAPI = {
	list: async function () {
		return axiosRequest(
			"get",
			"https://api.premint.xyz/v1/8299062e3d4e63d29ffc73af481bc1b9bde2b62439a4da549eace7cd08abee93/"
		);
	},
};
export const staffApi = {
	list: async function () {
		return axiosRequest("post", "/staff/list");
	},

	register: async function (member) {
		return axiosRequest("post", "/staff/register", member);
	},
	authorize: async function (username, enabled) {
		return axiosRequest("post", "/staff/authorize", { username, enabled });
	},
	delete: async function (usernames) {
		return axiosRequest("post", "/staff/delete", { usernames });
	},
	update: async function (member) {
		return axiosRequest("post", "/staff/update", member);
	},
};

export const mintersApi = {
	list: async function () {
		return axiosRequest("post", "/minters/list");
	},
	listNFTs: async function () {
		return axiosRequest("get", "/minters/getNFTs");
	},
	register: async function (minter) {
		return axiosRequest("post", "/minters/register", minter);
	},
	update: async function (minter) {
		return axiosRequest("post", "/minters/update", minter);
	},
	authorize: async function (wallet, authorize) {
		return axiosRequest("post", "/minters/authorize", { wallet, authorize });
	},
	delete: async function (wallets) {
		return axiosRequest("post", "/minters/delete", { wallets });
	},
	import: async function (wallets) {
		return axiosRequest("post", "/minters/import", wallets);
	},
	exportShipping: async function () {
		return axiosRequest("post", "/shippings/list", {});
	},
};

export const couponsApi = {
	list: async function () {
		return axiosRequest("post", "/coupons/list");
	},
	register: async function (coupon) {
		return axiosRequest("post", "/coupons/create", coupon);
	},
	update: async function (coupon) {
		return axiosRequest("post", "/coupons/update", coupon);
	},
	delete: async function (codes) {
		return axiosRequest("post", "/coupons/delete", { codes });
	},
};
