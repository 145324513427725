<template>
  <Layout>
    <PageHeader :title="title" :breadcrumbs="breadcrumbs" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="orderList">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">Minters</h5>
              <div class="flex-shrink-0 gap-1">
                <button
                  type="button"
                  class="btn btn-info"
                  id="export-shipping"
                  @click="getShipping"
                >
                  <div v-if="exporting" class="d-flex"><i class="mdi mdi-spin mdi-loading mx-2"></i> Exporting...</div>
                  <div v-else class="d-flex">Export Shipping</div>
                </button>
              </div>
            </div>
          </div>
          <div
            class="card-body border border-dashed border-end-0 border-start-0"
          >
            <form>
              <div class="row g-3 filters-form">
                <div class="col-xxl-5 col-sm-6">
                  <div class="search-box">
                    <input
                      type="text"
                      class="form-control search"
                      placeholder="Search by address or ENS"
                      v-model="searchQuery"
                    />
                    <i class="ri-search-line search-icon"></i>
                  </div>
                </div>
             
                <div class="col-xxl-2 col-sm-4">
                  <button type="button" class="btn btn-primary" @click="search">
                    <i class="ri-equalizer-fill align-bottom me-1"></i> Search
                  </button>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
          </div>
          <div class="card-body pt-0">
            <div>
              <ul
                class="nav nav-tabs nav-tabs-custom nav-success mb-3"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active All py-3"
                    data-bs-toggle="tab"
                    id="All"
                    href="#home1"
                    role="tab"
                    aria-selected="true"
                  >
                    <i class="ri-team-line me-1 align-bottom"></i> All minters
                    <span class="badge bg-info align-middle ms-1">{{
                      mintersList.length
                    }}</span>
                  </a>
                </li>
              </ul>

              <div class="table-responsive table-card mb-1">
                <table class="table table-nowrap align-middle" id="orderTable">
                  <thead class="text-muted table-light">
                    <tr class="text-uppercase">
                      <th scope="col" style="width: 25px">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="checkAll"
                            value="option"
                          />
                        </div>
                      </th>
                      <th class="sort" data-sort="id" @click="sort('id')">NFT #</th>
                      <th class="sort" data-sort="wallet" @click="sort('ownerAddress')">Wallet</th>
                       <th class="sort" data-sort="full-name" @click="sort('fullname')" >Full Name</th>
                      <th class="sort" data-sort="shipping-address" @click="sort('shippingAddress')">Shipping Address</th>
                    </tr>
                  </thead>
                  <tbody
                    class="list form-check-all"
                    v-for="(record, index) of resultQuery"
                    :key="index"
                  >
                    <tr>
                      <th scope="row">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="chk_child"
                            value="option1"
                          />
                        </div>
                      </th>
                      <td class="wallet">
                        <router-link to="#" class="fw-medium link-primary"
                          >{{ parseInt(record.id) }}
                        </router-link>
                      </td>
                      <td class="alias">{{ record.ownerAddress }}</td>
                      <td class="profile">{{ record.fullname }}</td>
                      <td class="shipping-address">{{ record.shippingAddress }} </td>
                    
                   
                    </tr>
                  </tbody>
                </table>
                <div class="noresult" style="display: none">
                  <div class="text-center">
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">Sorry! No Result Found</h5>
                    <p class="text-muted">
                      We've searched all minters. We did not find any
                      minters for you search.
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <div class="pagination-wrap hstack gap-2">
                  <a
                    class="page-item pagination-prev disabled"
                    href="#"
                    v-if="page != 1"
                    @click="page--"
                  >
                    Previous
                  </a>
                  <ul class="pagination listjs-pagination mb-0">
                    <li
                      v-for="(pageNumber, index) in pages.slice(
                        page - 1,
                        page + 5
                      )"
                      :key="index"
                      @click="page = pageNumber"
                      :class="{
                        active: pageNumber == page,
                        disabled: pageNumber == '...',
                      }"
                    >
                      <a class="page" href="#">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a
                    class="page-item pagination-next"
                    href="#"
                    @click="page++"
                    v-if="page < pages.length"
                  >
                    Next
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>

<script>
import "@vueform/multiselect/themes/default.css";

import Layout from "@/layouts/main.vue";
import appConfig from "@/../app.config";
import PageHeader from "@/components/page-header";
// import axios from "axios";
import animationData from "@/components/widgets/Lootie/animations/search.json";
import Lottie from "@/components/widgets/Lootie";

import { mintersApi } from "@/lib/api";
import { compactShippingInfo } from "@/lib/utils";

export default {
  page: {
    title: "minters",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "minters",
      breadcrumbs: [],
      page: 1,
      perPage: 100,
      pages: [],
      activePointCount: null,
      listCategory: "all",
      searchQuery: "",
      currentSort:'name',
      currentSortDir:'asc',
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "d M, Y",
        mode: "range",
      },
      defaultOptions: {
        animationData: animationData,
      },
      minters: [],
      exporting: false
    };
  },
  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
  },
  computed: {
    mintersList() {
      return this.paginate(this.minters);
    },
    // sortedMinters() {
    //   return this.minters.sort((a,b) => {
    //     let modifier = 1;
    //     if(this.currentSortDir === 'desc') modifier = -1;
    //     if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
    //     if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
    //     return 0;
    //   });
    // },
    resultQuery() {
      if (this.searchQuery.trim().length) {
        const search = this.searchQuery.toLowerCase();
        
        return this.mintersList.filter((data) => {
          return (
            data?.id?.toLowerCase().includes(search) ||
            data?.ownerAddress?.toLowerCase().includes(search) ||
            data?.shippingAddress?.toLowerCase().includes(search) ||
            data?.fullname?.toLowerCase().includes(search)
          );
        });
      } else {
        return this.mintersList;
      }
    },
  },
  watch: {},
  created() {
    this.setPages();
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  beforeMount() {
    mintersApi.listNFTs().then((response) => {
      const { success, result } = response;
      if (success) {
        result.forEach((row) => {
          this.minters.push(row);
        });
        this.postProcessItems();
      } else {
        console.log(result);
      }
    });
  },
  mounted() {
    var checkAll = document.getElementById("checkAll");
    if (checkAll) {
      checkAll.onclick = function () {
        var checkboxes = document.querySelectorAll(
          '.form-check-all input[type="checkbox"]'
        );
        if (checkAll.checked == true) {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = true;
            checkbox.closest("tr").classList.add("table-active");
          });
        } else {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = false;
            checkbox.closest("tr").classList.remove("table-active");
          });
        }
      };
    }
  },
  methods: {
    setPages() {
      let numberOfPages = Math.ceil(this.minters.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(minters) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return minters.slice(from, to);
    },
    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
     return this.minters.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      });
     // this.resultQuery;
    },
 
    postProcessItems() {
     
      this.minters.forEach(minter => {
        minter.shippingAddress = "";
        minter.fullname = "";
        if (minter.shippingInfo && minter.shippingInfo.street) {
          minter.shippingAddress = minter.shippingInfo.street + " " + minter.shippingInfo.city + ", " + minter.shippingInfo.state + " " + minter.shippingInfo.zipcode;
        }
         if (minter.shippingInfo && minter.shippingInfo.fullname) {
           minter.fullname = minter.shippingInfo.fullname;
         }
      })
   
    },

    search() {
      return this.resultQuery;
    },
    extractDate(datetime) {
      if (!datetime) {
        return null;
      }
      const parts = datetime && datetime.split(" ");
      return parts[0];
    },
    extractTime(datetime) {
      if (!datetime) {
        return null;
      }
      const parts = datetime && datetime.split(" ");
      return parts[1];
    },
    mintersEnable(event, member) {
      mintersApi
        .authorize(member.wallet, event.target.checked)
        .then((response) => {
          const { success, result } = response;
          if (!success) {
            console.log(result);
          } else {
            member.enabled = event.target.checked;
          }
        });
    },
    async getShipping() {
      this.exporting = true
      const data = await mintersApi.exportShipping()
      if (data && data.success) {
        let csvContent = 'Full name,Phone,Email,Street,City,Zip,State,TokenId,Wallet\n'
        data.result.Items.forEach(entry => {
          csvContent += compactShippingInfo(entry)
        })
        const blob = new Blob([csvContent], { type: 'text/csv' })
        const dlink = document.createElement('a')
        dlink.download = 'Shipping Info ' + new Date().toLocaleDateString('en-us').replaceAll('/', '-')
        dlink.href = window.URL.createObjectURL(blob)
        dlink.onclick = function() {
          const that = this;
          setTimeout(function() {
            window.URL.revokeObjectURL(that.href);
          }, 100);
        };
        dlink.click()
        dlink.remove()
      } else {
        alert(`There was an error retrieving shipping addresses ${data.result}`)
      }
      this.exporting = false
    },
    async resync() {
      let value;
      try {
        value = JSON.parse(document.getElementById("resyncText").value);
      } catch (e) {
        return;
      }
      if (Object.keys(value).length) {
        await mintersApi.import(value).then((resp) => {
          console.log(resp);
          if (resp.success) {
            document.getElementById("close-modal-resync").click();
            document.getElementById("resyncForm").reset();
            mintersApi.list().then((response) => {
              const { success, result } = response;
              if (success) {
                result.Items.forEach((row) => {
                  this.minters.push(row);
                });
                this.postProcessItems();
              } else {
                console.log(result);
              }
            });
          }
        });
      }
    },
  },
};
</script>
